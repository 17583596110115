import React from 'react'

import { IMyPopupBaseBasic, MyPopupBase } from '../MyPopupBase/MyPopupBase';

import './PopUpMessage.css'

export interface IPopUpMessage extends IMyPopupBaseBasic {
    header : string,
    text : string,
    buttonId : string
}

export default function PopUpMessage(props :IPopUpMessage) {
    return <MyPopupBase zIndex={props.zIndex} closePopup={props.closePopup} width={50}>
        <div className="PopupMessageHeader" >{props.header}</div>
        <p className="PopupMessageText">{props.text}</p>
        <button id={props.buttonId} className="PrimaryButton" onClick={() => props.closePopup()}>Ok</button>
    </MyPopupBase>
}
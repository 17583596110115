import React from 'react'

import BalanceInformationBlock from './BalanceInformationBlock'
import { IBaseAdminContainer, IBaseAdminContainerState, BaseAdminContainer } from '../Base/BaseAdminContainer'
import DefaultTariffsPopup from './DefaultTariffsPopup'
import IncomesTable from './IncomesTable'
import { ITextProvider } from '../../../Helpers/TextProvider/TextProvider'
import OutcomesTable from './OutcomesTable'
import RechargeBalancePopup from './RechargeBalancePopup'
import PreloaderCompactDefault from '../../../Components/PreloaderCompact/PreloaderCompact'
import StripePayment from '../StripePayment/StripePayment'

import BalanceUserInfo from '../../../Models/Balance/BalanceUserInfo'
import TariffUser from '../../../Models/Tariffs/TariffUser'
import Tariff from '../../../Models/Tariffs/Tariff'
import Pair from '../../../Models/Pair'
import PaymentInfo from '../../../Models/Payment/PaymentInfo'

import Currency from '../../../Models/Currencies/Currency'

import './BalanceUI.css'

interface IBalanceUI extends IBaseAdminContainer {
    textProvider : ITextProvider
    isLoaded : boolean
    balanceInfo : BalanceUserInfo
    currentTariff : TariffUser
    defaultTariffs : Tariff[]
}

interface IBalanceUIState extends IBaseAdminContainerState {
    defaultTariffsPopupNeeded : boolean
    rechargeBalancePopupNeeded : boolean
    paymentInfo : PaymentInfo | undefined
}

export default class BalanceUI extends BaseAdminContainer<IBalanceUI, IBalanceUIState> {
    constructor(props : IBalanceUI) {
        super(props);
        this.state = {
            ...BaseAdminContainer.InitialState,
            defaultTariffsPopupNeeded : false,
            rechargeBalancePopupNeeded : false,
            paymentInfo : undefined
        };
    }

    getTariffInfoItem(prompt : string, text : string, uppercase : boolean, secondaryText : string) : JSX.Element {
        let style : React.CSSProperties = { };
        if (uppercase)
            style.textTransform = 'uppercase';
        return <div className="BalanceUIContainer">
            <p className="Prompt">{prompt}</p>
            <p className="BalanceUIImportantInfo" style={style}>{text} <span className="BalanceUITariffItem Prompt">{secondaryText}</span></p>
        </div>;
    }

    getMoney(count : number, currency : Currency) {
        if (!currency) return '?'
        return count.toFixed(2) + currency.symbol;
    }

    getMoneyInMonth(count : number, currency : Currency) {
        return this.getMoney(count, currency) + this._language.balanceInMonth;
    }

    dateToString(d : Date) : string {
        return `${d.getDate()}/${d.getMonth()+1}/${d.getFullYear()}`;
    }

    getPaymentBlock() : JSX.Element {
        return <div className="BalanceUIPaymentContainer Shadow">
            <div className="BalanceUIPaymentContainerInner">
                <p className="SubHeader">{this._language.balancePayments}</p>
                <IncomesTable textProvider={this._language} records={this.props.balanceInfo.incomes} dateToString={d => this.dateToString(d)}/>
            </div>
            <div className="BalanceUIPaymentContainerInner">
                <p className="SubHeader">{this._language.balanceBillingHistory}</p>
                <OutcomesTable textProvider={this._language} records={this.props.balanceInfo.outcomes} dateToString={d => this.dateToString(d)}/>
            </div>
        </div>;
    }

    baseAdminContainerRender(): JSX.Element {
        let content : JSX.Element[];
        if (!this.props.isLoaded)
            content = [ <PreloaderCompactDefault /> ]
        else
            content = [
                <BalanceInformationBlock 
                    textProvider={this._language}
                    defaultTariffs={this.props.defaultTariffs}
                    currentTariff={this.props.currentTariff}
                    balanceInfo={this.props.balanceInfo}
                    getMoney={(count,c) => this.getMoney(count, c)}
                    getMoneyInMonth={(count,c) => this.getMoneyInMonth(count, c)}
                    defaultTariffsPopupChange={v => this.changeValueState(new Pair('defaultTariffsPopupNeeded', v))}
                    rechargeBalancePopupChange={v => this.changeValueState(new Pair('rechargeBalancePopupNeeded', v))}/>,
                this.getPaymentBlock()
            ]

        let defaultTariffsPopup = null;
        if (this.state.defaultTariffsPopupNeeded)
            defaultTariffsPopup = <DefaultTariffsPopup 
                                        zIndex={100} 
                                        closePopup={() => this.changeValueState(new Pair('defaultTariffsPopupNeeded', false))}
                                        width={'default'}
                                        textProvider={this._language}
                                        tariffs={this.props.defaultTariffs}/>

        let rechargeBalancePopup = null;
        let paymentUI = null;
        if (this.state.rechargeBalancePopupNeeded) {
            rechargeBalancePopup = <RechargeBalancePopup 
                                        zIndex={100}
                                        closePopup={() => this.changeValueState(new Pair('rechargeBalancePopupNeeded', false))}
                                        width={'default'}
                                        textProvider={this._language}
                                        onResult={info => this.changeValueState(new Pair('paymentInfo', info))}/>
            
            if (this.state.paymentInfo)
                paymentUI = <StripePayment 
                                zIndex={200} 
                                closePopup={() => this.changeValueState(new Pair('paymentInfo', undefined))}
                                width={'default'}
                                info={this.state.paymentInfo}
                                textProvider={this._language}/>
        }

        return <div className="BalanceUIContainer MainContainer">
            <p className="Header">{this._language.balanceSubscriptionsAndPayments}</p>
            <p className="Prompt">{this._language.balancePrompt}</p>
            {content}
            {defaultTariffsPopup}
            {rechargeBalancePopup}
            {paymentUI}
        </div>
    }
}
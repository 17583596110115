import React, { useState } from 'react'
import {Link} from "react-router-dom"

import { InputV2, InputV2Props } from '../../Components/InputV2/InputsV2'
import PopUpMessage from '../../Components/Popups/PopUpMessage/PopUpMessage'

import ILanguagePhrase from '../../Helpers/LanguagePhrase/ILanguagePhrase'
import { IRoutesAgent } from '../../Helpers/Routes/RoutesAgent'
import RoutesUrl from '../../Helpers/Routes/RoutesUrl'
import Validator from '../../Helpers/Validator/Validator'

import './ResetPassword.css'

const emailInputId = 'ResetPasswordEmailInputId', 
      resetPasswordButtonId = 'ResetPasswordResetPasswordButtonId', 
      signInButtonId = 'ResetPasswordSignInButtonId',
      messagePopupButtonId = 'ResetPasswordMessagePopupButtonId';

export { emailInputId, resetPasswordButtonId, signInButtonId, messagePopupButtonId }

interface IResetPasswordUI {
    textProvider : ILanguagePhrase
    routesAgent : IRoutesAgent
    email : string
    popupMessage : string
    onChange(email : string) : void
    onPopupClosed() : void
}

export default function ResetPasswordUI(props : IResetPasswordUI) {
    const [ forceError, forceErrorChange ] = useState(false);
    const [ currentEmail, currentEmailChange ] = useState(props.email);

    function onClick() {
        forceErrorChange(true);
        if (!emailValid) return;

        props.onChange(currentEmail);
    }

    const emailValid = Validator.IsEmailTemplate(currentEmail);

    let popup;
    if (props.popupMessage && props.popupMessage.length > 0)
        popup = <PopUpMessage zIndex={100} closePopup={() => props.onPopupClosed()} header='' text={props.popupMessage} buttonId={messagePopupButtonId}/>

    return <div className='CommonPage'>
        <div className="CommonPageInner">
            <p className="CommonPageHeader">{props.textProvider.passwordReset}</p>
            {InputV2(new InputV2Props(
                props.textProvider.email,
                forceError,
                props.textProvider.uncorrectEmail,
                currentEmail,
                emailValid,
                v => currentEmailChange(v),
                'text', false, 'fit', false, emailInputId))}
            <button id={resetPasswordButtonId}
                    className="PrimaryButton WideButton" 
                    style={{marginTop:'2.5vh'}} 
                    onClick={() => onClick()}>{props.textProvider.passwordReset}</button>
            <div id={signInButtonId} className='SecondaryButton' onClick={() => props.routesAgent.navigateTo(RoutesUrl.SignIn)}>{props.textProvider.signInBut}</div>
            {popup}
        </div>
    </div>
}
import React from "react"
import SettingsPageUI from "./SettingsPageUI"
import { ITextProvider, Languages, TextProvider } from "../../../Helpers/TextProvider/TextProvider";
import BaseContainer from "../../BaseContainer";
import BaseUser from "../../../Models/Users/BaseUser";
import IUserAction from "../../../Actions/UserActions/IUserAction";
import Preloader from "../../../Components/Preloader/Preloader";

import Language from "../../../Models/Users/Language";
import UserActions from "../../../Actions/UserActions/UserActions";
import UserInfo from "../../../Models/Users/UserInfo";
import ILanguagePhrase from "../../../Helpers/LanguagePhrase/ILanguagePhrase";

interface IProps {
    textProvider : ITextProvider | null
}

export interface IState {
    user : BaseUser
    isLoad : boolean
    errorMessage : string
}

class SettingsPage extends BaseContainer<IProps,IState>{
    private readonly _textProvider : ITextProvider;
    private readonly _language : ILanguagePhrase
    constructor(props : IProps){
        super(props);
        this._language = (this._textProvider = props.textProvider ?? new TextProvider()).GetLang();

        this.state = {
            user : new BaseUser('','','','','','','','',Language.Ru,false),
            isLoad : false,
            errorMessage : '',
        }
    }

    private readonly userActions : IUserAction = new UserActions();

    componentDidMount = () => {
        this.userActions.GetUserProfileById("").then((user:BaseUser|string) => {
            this.fillUserState(user as BaseUser);
        });
    }

    fillUserState = (user : BaseUser) => {
        this.setState({
            user : user,
            isLoad : true
        });
    }


    changeState=(name:string,value:string,errorName:string,errorValue:string) => {
        this.setState((state) => {
            return{
                ...state,
                [name]:value,
                [errorName]:errorValue
            }
        })
    }

    changeLanguage = (languageISO : string) => {
        this._textProvider.SetLangByISO(languageISO)
        let user = this.state.user;
        user.languageType = (Languages.find(l => l.iso === languageISO) as ILanguagePhrase).lang;
        this.updateUser(user).then(() => window.location.reload());
    }

    editUser(info : UserInfo) {
        let user = this.state.user;
        BaseUser.edit(user, info.firstName, info.lastName, info.email, info.phone);
        return this.updateUser(user);
    }

    updateUser(user : BaseUser) {
        this.setState(state => { return {...state, user: Object.assign({},user)} });
        return this.userActions.UpdateUserInfo(user).then(errorMessage => {
            if (!errorMessage) return true;

            this.setState(state => { return { ...state, errorMessage: errorMessage }});
            return false;
        });
    }

    render(){
        let user = this.state.user;
        let content = <Preloader/>
        if(this.state.isLoad)
            content = (<SettingsPageUI
                            textProvider={this._textProvider}
                            language={this._language}
                            info={new UserInfo(user.firstName, user.lastName, user.email, user.phone)}
                            changeLanguage={iso => this.changeLanguage(iso)}
                            editUser={info => this.editUser(info)}
                            errorMessage={this.state.errorMessage}
                            errorMessageClose={() => this.setState(state => { return { ...state, errorMessage: '' } })}
                        />);
        return content;
    }
}


export default SettingsPage;
import React, { useEffect } from "react"
import Hierarchy from "../../Models/Organizations/Hierarchy";
import Department from "../../Models/Organizations/Department/Department";

import ShevronImg from "../../Media/Img/shevron.png"
import ActiveShevronImg from "../../Media/Img/activeShevron.png"
import "./HierarchyComponent.css"
import NameIndexValues from "../../Models/UI/NameIndexValues";

import AddImg from "../../Media/Img/add.png";

//value scaling
import valueScaler from "../../Helpers/ValueScalers/AvgValueScaler";
import { useState } from "react";
import DepartmentView from "../../Models/Organizations/Department/DepartmentView";
import { IMyPopupBase, MyPopupBase } from "../Popups/MyPopupBase/MyPopupBase";

export interface IHierarchy extends IMyPopupBase {
    hierarchy:Hierarchy
    nowDepartment : Department
    listIndexes : NameIndexValues[]
    addDepartmentInFavoritesDepartments(dep : Department) : void
}

export default function HierarchyComponent(props:IHierarchy){
    const isNeededToExtend = (curr : Department) => curr.id === props.nowDepartment.id || (curr.subDepartments?.some(isNeededToExtend) ?? false);

    const getExtendableHierarchy = (currentDepartment : Department) : DepartmentView => {
        let curr = new DepartmentView(currentDepartment, isNeededToExtend(currentDepartment));
        curr.subDepartments = currentDepartment.subDepartments.map(getExtendableHierarchy);
        return curr;
    };

    //array because not changing BUT need to re-render when updated
    let head = getExtendableHierarchy(props.hierarchy.mainDepartment as Department);
    const [extendableHierarchy, extendableHierarchyChange] = useState([head]);

    const changeExtended = (item : DepartmentView) => {
        item.isExtended = !item.isExtended;
        extendableHierarchyChange([extendableHierarchy[0]]);
    }

    const addInFavorites = (item : DepartmentView) => {
        props.addDepartmentInFavoritesDepartments(item)
        item.isExtended = true;
        extendableHierarchyChange([extendableHierarchy[0]])
    }

    const getElementForDep = (item : DepartmentView, level: number) : JSX.Element[] => {
        let elementsForValues = props.listIndexes.map(insideItem => {
            let value = valueScaler.ScaleAvgValue(insideItem.findChartDataByDep(item)._avgValue, insideItem.type);
            return <td><p className="HierarchyComponentColoredBlock Prompt" 
                        style={{backgroundColor: insideItem.colorStrategy.GetColorCode(value)}}>{Math.round(value)}</p></td>
        });

        let shevronImg = item.subDepartments.length == 0 ? '' : (props.nowDepartment.id === item.id ? ActiveShevronImg : ShevronImg)
        let shevronItem = shevronImg.length == 0 
                            ? <div /> 
                            : <img className='HierarchyComponentShevron'
                                   src={shevronImg} 
                                   style={{transform: item.isExtended ? 'rotate(90deg)' : '' }} 
                                   onClick={() => changeExtended(item)}/>

        let nameWidth = document.body.clientWidth > 1024 ? 15 : 20;
        let rows = [<tr style={{marginLeft: `${level}vw`}}>
            <td>{shevronItem}</td>
            <td style={{width:`${nameWidth-level}vw`}}><p className="Prompt" style={{wordBreak:'break-all'}}>{item.name}</p></td>
            {elementsForValues}
            <td><img src={AddImg} className="addButtonDepInFavorites" onClick={() => addInFavorites(item)}/></td>
        </tr>]

        if (item.isExtended)
            rows = rows.concat(item.subDepartments.map(sub => getElementForDep(sub as DepartmentView, level+1))
                                                  .reduce((prev,acc) => prev.concat(acc), []))

        return rows;
    }

    return <MyPopupBase zIndex={props.zIndex} closePopup={() => props.closePopup()} width={'default'}>
        <div className="HierarchyComponent">
            <div className="HierarchyComponentInner">
                <table className="HierarchyComponentTable">
                    <tr>
                        <td></td>
                        <td></td>
                        {props.listIndexes.map(item => <td className="Prompt HierarchyComponentPrompt">{item.nameIndex}</td>)}
                        <td className="addButtonDepInFavorites"></td>
                    </tr>
                    {getElementForDep(extendableHierarchy[0], 0)}
                </table>
            </div>
        </div>
    </MyPopupBase>
}
import React from 'react'

import { IMyPopupBase, MyPopupBase } from '../../../Components/Popups/MyPopupBase/MyPopupBase'

import ILanguagePhrase from '../../../Helpers/LanguagePhrase/ILanguagePhrase'
import Tariff from '../../../Models/Tariffs/Tariff'

import './BalanceUI.css'
import './DefaultTariffsPopup.css'

interface IDefaultTariffsPopup extends IMyPopupBase {
    textProvider : ILanguagePhrase
    tariffs : Tariff[]
}

function getSpecialTariffTemplate(lang : ILanguagePhrase) {
    if (lang.specialTariffPrompt.length === 0) return <React.Fragment />

    const prompts = [];
    for (let i = 1; i < lang.specialTariffPrompt.length; i++)
        prompts.push(<p className='Prompt'>{lang.specialTariffPrompt[i]}</p>)
    prompts.push(<p className='Prompt'><a href='mailto:support@whyeva.com'>support@whyeva.com</a></p>)

    return <div className='DefaultTariffsPopupSpecial Shadow'>
        <p className='SubHeader'>{lang.specialTariffPrompt[0]}</p>
        <div>
            {prompts}
        </div>
    </div>
}

function getTemplate(props : IDefaultTariffsPopup, tariff : Tariff) {
    return <div className="Shadow">
        <p className="SubHeader">{tariff.innerName}</p>
        <p className="Prompt">{tariff.monthlyPayment} {tariff.currency.symbol}{props.textProvider.balanceInMonth} {props.textProvider.balancePerEmployee}</p>
        <p className="Prompt">{tariff.maxManagers} {props.textProvider.managers}</p>
        <p className="Prompt">{tariff.maxEmployees} {props.textProvider.employees}</p>
        <p className="Prompt">{tariff.maxHierarchies} {props.textProvider.structures}</p>
    </div>
}

export default function DefaultTariffsPopup(props : IDefaultTariffsPopup) {
    return <MyPopupBase zIndex={props.zIndex} closePopup={() => props.closePopup()} width={[70, 95]}>
        <p className="Header">{props.textProvider.balancePlanManage}</p>
        <div className="DefaultBalancesPopupInnerContainer">
            {props.tariffs.map(t => getTemplate(props, t)).concat([getSpecialTariffTemplate(props.textProvider)])}
        </div>
    </MyPopupBase>
}

class RoutesUrl {

    public static readonly privacyPolicyUrl : string = "https://datascope.fr/CGU.html";

    public static readonly dataProtectionPolicyUrl : string = "https://datascope.fr/policy-site.html";

    public static idKey = "id";

    public static Root = "/";

    public static SignIn = "/SignIn";

    public static SignUp = "/SignUp";
    
    public static ResetPassword = "/ResetPassword";

    public static NoHierarchy = "/NoHierarchy";



    public static ReportingInterface = "/Report";

    public static Settings = RoutesUrl.ReportingInterface + "/Settings";

    public static Reports = RoutesUrl.ReportingInterface + "/Reports";

    public static DailyReport = RoutesUrl.ReportingInterface + '/Daily'

    public static Organization = RoutesUrl.ReportingInterface + "/Organization";

    
    public static CRMInterface = '/CRM';

    public static CRMOrganizations = RoutesUrl.CRMInterface + '/Organizations';

    public static CRMOrganization = RoutesUrl.CRMInterface + '/Organization'


    public static AdminInterface = "/Admin";

    public static Users = RoutesUrl.AdminInterface + '/Users';
    
    public static ManagerSettings = RoutesUrl.AdminInterface + "/Manager"

    public static EmployeeSettings = RoutesUrl.AdminInterface + "/Employee";

    public static Structures = RoutesUrl.AdminInterface + '/Structures';

    public static Balance = RoutesUrl.AdminInterface + '/Balance';

    public static AdminProfile = RoutesUrl.AdminInterface + '/Profile';

    public static AdminInterfaceDefaultPath = RoutesUrl.Users;
}

export default RoutesUrl;
import React from 'react'

import {IBaseAdminSettings, IBaseAdminSettingsState, BaseAdminSettings} from "../BaseSettings/BaseAdminSettings"
import UserInfoComponent from "../../../Components/UserInfoComponent/UserInfoComponent"

import RoutesUrl from '../../../Helpers/Routes/RoutesUrl';
import UserInfo from '../../../Models/Users/UserInfo';


import './ManagerSettingsUI.css'
import PreloaderCompactDefault from '../../../Components/PreloaderCompact/PreloaderCompact';
import PopUpMessage from '../../../Components/Popups/PopUpMessage/PopUpMessage';

interface IManagerSettingsUI extends IBaseAdminSettings {
    isLoaded : Boolean
    info : UserInfo|undefined
    isBlocked : Boolean
    isCurrentUser : boolean
    updateManager(data : UserInfo) : Promise<boolean | undefined>
    deleteManager() : void
    blockManagerChange() : void
    errorMessage : string
    errorMessageClose() : void
}

interface IManagerSettingsUIState extends IBaseAdminSettingsState {
    
}

class ManagerSettingsUI extends BaseAdminSettings<IManagerSettingsUI, IManagerSettingsUIState> {
    constructor(props : IManagerSettingsUI) {
        super(props);
        this.state = {
            ...BaseAdminSettings.InitialState,
        }
    }

    goToManagers() {
        window.location.href = RoutesUrl.Users;
    }

    getLink() : string {
        return this._language.managers;
    }

    onLinkClicked() : void {
        return this.goToManagers();
    }

    getName() : string {
        return this.props.info?.firstName ?? '';
    }

    getButtons() : JSX.Element[] {
        return [
            
        ];
    }

    getMainContent() : JSX.Element {
        if (!this.props.isLoaded)
            return <PreloaderCompactDefault />

        let settingsBlock;
        if (!this.props.isCurrentUser) {
            settingsBlock = <React.Fragment>
                <p className="Header" style={{marginTop: '10vh'}}>{this._language.accountSettings}</p>
                <div className="ManagerSettingsUIButtonsContainer">
                    <button className="WhiteButton" onClick={() => this.props.deleteManager()}>{this._language.delete}</button>
                    <button className="WhiteButton" onClick={() => this.props.blockManagerChange()}>
                        {this.props.isBlocked ? this._language.managerUnblock : this._language.managerBlock}
                    </button>
                </div>
            </React.Fragment>
        }

        let errorMessage;
        if (this.props.errorMessage)
            errorMessage = <PopUpMessage 
                                zIndex={100} 
                                closePopup={() => this.props.errorMessageClose()} 
                                header={this._language.error} 
                                text={this.props.errorMessage}
                                buttonId='ManagerSettingsUIMessagePopupButtonId'/>
        return <div className="ManagerSettinsUIMainContainer">
            {this.props.info && <UserInfoComponent     
                                    align="vertical"
                                    info={this.props.info}
                                    textProvider={this._language}
                                    actionText={this._language.update}
                                    action={r => this.props.updateManager(r)}
                                    onCancel={() => { }}/>}
            {settingsBlock}
            {errorMessage}
        </div>
    }
}

export default ManagerSettingsUI;
import React from "react"

//components
import HeaderContainer from "../../../Components/Report/HeaderContainer";
import Footer from "../../../Components/Report/Footer/Footer";
import InformationComponent from '../../../Components/InformationComponent/InformationComponent'
import LanguageChangeComponent from '../../../Components/LanguageChangeComponent/LanguageChangeComponent'

import ILanguagePhrase from "../../../Helpers/LanguagePhrase/ILanguagePhrase";

import PersonImg from "../../../Media/Img/person.png";

import "./SettingsPage.css";
import UserInfoComponent from '../../../Components/UserInfoComponent/UserInfoComponent'
import UserInfo from "../../../Models/Users/UserInfo";
import PopUpMessage from "../../../Components/Popups/PopUpMessage/PopUpMessage";
import { ITextProvider } from "../../../Helpers/TextProvider/TextProvider";

interface ISettingsPageUI{
    info : UserInfo
    changeLanguage(nowLang : string) : void
    editUser(result : UserInfo) : Promise<boolean | undefined>
    textProvider : ITextProvider
    language : ILanguagePhrase
    errorMessage : string
    errorMessageClose() : void
}

function SettingsPageUI(props:ISettingsPageUI){
    let errorMessage;
    if (props.errorMessage)
        errorMessage = <PopUpMessage 
                            zIndex={100}
                            closePopup={() => props.errorMessageClose()}
                            header={props.language.error}
                            text={props.errorMessage}
                            buttonId='SettingsPageUIErrorMessagePopupButtonId'/>

    return(<React.Fragment>
             <HeaderContainer
                     textProvider = {props.language}
                     uri = {window.location.pathname}
                />
              <div className = "settingsPage">
                <div className = "settingPageUserInfoContainer">
                    <div>
                        <div  className="settingsUserAvatar">
                            <img src={PersonImg}/>
                        </div>
                    </div>
                    <div className="settingsPageForm">
                        <p className="Header SettingsPageHeader">{props.language.personalInformation}</p>
                        <UserInfoComponent 
                            align='table'
                            info={props.info}
                            textProvider={props.language}
                            actionText={props.language.save}
                            action={r => props.editUser(r)}
                            onCancel={() => { }}/>
                        <div className="Header SettingsPageHeader" style={{marginTop: '5vh'}}>{props.language.interfaceSettings}</div>

                        <div className="coupleAreaSettings">
                            <div className="inputContainerSettings">
                                <LanguageChangeComponent 
                                    vertical="fit" 
                                    currentLanguage={props.language} 
                                    textProvider={props.textProvider}
                                    changeLanguage={v => { props.changeLanguage(v); window.location.reload()}}
                                    id='SettingsPageUILanguageChange'/>
                            </div>
                            <div className="inputContainerSettings">        
                            </div>
                        </div>        
                        <InformationComponent textProvider={props.language}/>     
                    </div>            
                </div>
                </div>
                <Footer text = {props.language.footerText}/>
          </React.Fragment>)
}

export default SettingsPageUI;
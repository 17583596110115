import React from 'react'

import RoutesUrl from '../../Helpers/Routes/RoutesUrl'

import ILocalStorageAgent from '../../LocalStorageWorker/ILocalStorageAgent'
import ILanguagePhrase from '../../Helpers/LanguagePhrase/ILanguagePhrase'
import { ITokenParser } from '../../Helpers/TokenParser'
import { IRoutesAgent } from '../../Helpers/Routes/RoutesAgent'

import Logo from "../../Media/Img/logo_actual_main.png";

import './SimpleHeaderWithLinks.css'
import Role from '../../Models/Role'

interface ISimpleHeaderWithLinks {
    tokenParser : ITokenParser
    localStorageAgent : ILocalStorageAgent
    routesAgent : IRoutesAgent
    language : ILanguagePhrase
}

export default function SimpleHeaderWithLinks(props : ISimpleHeaderWithLinks) {
    function navigate(link : string) { props.routesAgent.navigateTo(link) };
    function getButton(link : string, text : string) { return <button key={link} onClick={() => navigate(link)} className='SecondaryButton'>{text}</button> }

    const token = props.localStorageAgent.token;
    const roles = token ? props.tokenParser.GetRoles(token) : [];
    const buttons : JSX.Element[] = [
        getButton(RoutesUrl.Reports, props.language.reports)
    ];
    if (roles.some(r => r === Role.CompanyAdministrator)) buttons.push(getButton(RoutesUrl.AdminInterfaceDefaultPath, props.language.adminInterface));
    if (roles.some(r => r === Role.Administrator)) buttons.push(getButton(RoutesUrl.CRMOrganizations, props.language.crm));

    return <React.Fragment>
        <div className="SimpleHeaderWithLinksContainer">
            <div className="CommonPageHeader SimpleHeaderWithLinksHeader"
                onClick={() => navigate("https://whyeva.com/")} style={{cursor: 'pointer'}}><img className="AdminImgHeader" src={Logo} /></div>
            <div className='SimpleHeaderWithLinksLinksContainer'>
                {buttons}
            </div>
        </div>
        <hr className="SimpleHeaderWithLinksHr"/>
    </React.Fragment>
}

import React from 'react'

import "reflect-metadata";
import {BrowserRouter, Route, Switch}  from "react-router-dom";
import RoutesUrl from "./Helpers/Routes/RoutesUrl";
import RedirectOnLoad from './Containers/RedirectOnLoad';
import OrganizationPage from "./Containers/Report/Organization/OrganizationPage";
import DailyReport from './Containers/Report/DailyReport/DailyReport'
import SignUpPage from "./Containers/SignUp/SignUpPage";
import SignInPage from "./Containers/SignIn/SignInPage";
import ResetPassword from './Containers/ResetPassword/ResetPassword'
import Context, { IAppState } from "./AppContext";
import SettingsPage from './Containers/Report/Settings/SettingsPage';
import Preloader from './Components/Preloader/Preloader';
import BaseContainer from './Containers/BaseContainer';
import NoHierarchyPage from "./Containers/NoHierarchy/NoHierarchyPage"
import ManagerSettings from "./Containers/Admin/ManagerSettings/ManagerSettings"
import EmployeeSettings from './Containers/Admin/EmployeeSettings/EmployeeSettings';
import Structures from './Containers/Admin/Structures/Structures'
import Balance from './Containers/Admin/Balance/Balance'
import Profile from './Containers/Admin/Profile/Profile'
import CRMOrganizations from './Containers/CRM/CRMOrganizations/CRMOrganizations';
import CRMOrganizationSettings from './Containers/CRM/CRMOrganizationSettings/CRMOrganizationSettings';
import Users from './Containers/Admin/Users/Users';

import './css/Blocks.css'
import './css/Buttons.css'
import './css/Texts.css'
import "./App.css"

interface IApp {

}

class App extends BaseContainer<{}, IAppState>
{
  constructor(props : IApp){
    super(props);
    this.state = {
      isDemoVersion : false,
      isLoad : false
    }
  }

  componentDidMount = () => {
      this.setState({isLoad : true});
  }


  render(){
    
    let content = <Preloader/>

    if(this.state.isLoad)
      content = (<Context.Provider value={this.state}>
                    <BrowserRouter>
                      <Switch>
                        <Route exact path = {RoutesUrl.Root}             component = {RedirectOnLoad}/>
                        <Route exact path = {RoutesUrl.SignIn}           component = {SignInPage}/>
                        <Route exact path = {RoutesUrl.SignUp}           component = {SignUpPage}/>
                        <Route exact path = {RoutesUrl.ResetPassword}    component = {ResetPassword}/>
                        <Route exact path = {RoutesUrl.NoHierarchy}      component = {NoHierarchyPage}/>
                        
                        <Route exact path = {RoutesUrl.Reports}          component = {OrganizationPage}/>
                        <Route exact path = {RoutesUrl.DailyReport}      component = {DailyReport}/>
                        <Route exact path = {RoutesUrl.Settings}         component = {SettingsPage}/>

                        <Route exact path = {RoutesUrl.Users}            component = {Users}/>
                        <Route exact path = {RoutesUrl.ManagerSettings}  component = {ManagerSettings}/>
                        <Route exact path = {RoutesUrl.EmployeeSettings} component = {EmployeeSettings}/>
                        <Route exact path = {RoutesUrl.Structures}       component = {Structures}/>
                        <Route exact path = {RoutesUrl.Balance}          component = {Balance} />
                        <Route exact path = {RoutesUrl.AdminProfile}     component = {Profile} />

                        <Route exact path = {RoutesUrl.CRMOrganizations} component = {CRMOrganizations}/>
                        <Route exact path = {RoutesUrl.CRMOrganization}  component = {CRMOrganizationSettings}/>

                        <Route path = "*" component={RedirectOnLoad}></Route>
                      </Switch>
                    </BrowserRouter>
                  </Context.Provider>
                  )

    return(content);
  }

}


export default App;
import React from 'react'

import CloseBlack from "../../../Media/Img/closeBlack.png"

import './MyPopupBase.css'

export interface IMyPopupBaseBasic {
    zIndex : number,
    closePopup() : void
}

export interface IMyPopupBase extends IMyPopupBaseBasic {
    /**
     * Popup's width measured in 'vw'.
     * [ minWidth , maxWidth ]
     * number = [ number , number ]
     * default = [ 50, 95 ]
     */
    width : 'default' | number | [ number , number ]
}

function getWidth(v : 'default' | number | [ number , number ]) : [ number, number ] {
    if (v === 'default') return [ 50 , 95 ];
    else if (!Number.isNaN(Number(v))) return [ Number(v) , Number(v) ]; //number
    return v as [ number , number ];
}

export const MyPopupBase : React.FunctionComponent<IMyPopupBase> = props => {
    const [ minWidth , maxWidth ] = getWidth(props.width);
    const style : React.CSSProperties = {
        minWidth : `${minWidth}vw`,
        maxWidth : `${maxWidth}vw`
    }
    return <div className="MyPopupBaseContainer" style={{zIndex : props.zIndex}}>
        <div className="MyPopupBaseInner" style={style}>
            <img className="MyPopupBaseCloseImg" src={CloseBlack} onClick={() => props.closePopup()}></img>
            {props.children}
        </div>
    </div>
}
import React from 'react'

import BaseContainer from '../../BaseContainer'
import UsersUI from './UsersUI';

import BaseUser from '../../../Models/Users/BaseUser';
import EmployeeActions from '../../../Actions/EmployeeActions/EmployeeActions';
import IEmployeeActions from '../../../Actions/EmployeeActions/IEmployeeActions';
import { ITextProvider, TextProvider } from '../../../Helpers/TextProvider/TextProvider';
import IUserAction from '../../../Actions/UserActions/IUserAction';
import UserActions from '../../../Actions/UserActions/UserActions';
import EmployeeLocal from '../../../Models/Employees/EmployeeLocal';
import IOrganizationAction from '../../../Actions/OrganizationActions/IOrganizationAction';
import OrganizationActions from '../../../Actions/OrganizationActions/OrganizationActions';
import { IRoutesAgent, RoutesAgent } from '../../../Helpers/Routes/RoutesAgent';
import ILocalStorageAgent from '../../../LocalStorageWorker/ILocalStorageAgent';
import LocalStorageAgent from '../../../LocalStorageWorker/LocalStorageAgent';
import { IInteractiveHintActions, InteractiveHintActions } from '../../../Actions/InteractiveHintActions';

interface IUsers {
    employeeActions : IEmployeeActions | null
    interactiveHintActions : IInteractiveHintActions | null
    localStorageAgent : ILocalStorageAgent | null
    organizationActions : IOrganizationAction | null
    textProvider : ITextProvider | null
    routesAgent : IRoutesAgent | null
    userActions : IUserAction | null
}

interface IUsersState {
    isLoaded : boolean
    managers : BaseUser[]
    employees : EmployeeLocal[]
    errorMessage : string

    deletingId : string
}

export default class Users extends BaseContainer<IUsers, IUsersState> {
    private readonly _employeeActions : IEmployeeActions;
    private readonly _interactiveHintActions : IInteractiveHintActions;
    private readonly _localStorageAgent : ILocalStorageAgent;
    private readonly _organizationActions : IOrganizationAction;
    private readonly _textProvider : ITextProvider;
    private readonly _routesAgent : IRoutesAgent;
    private readonly _userActions : IUserAction;

    constructor(props : IUsers) {
        super(props);
        this.state = {
            isLoaded: false,
            managers: [],
            employees: [],
            errorMessage: '',
            
            deletingId: '',
        }

        this._employeeActions = props.employeeActions ?? new EmployeeActions();
        this._localStorageAgent = props.localStorageAgent ?? new LocalStorageAgent();
        this._interactiveHintActions = props.interactiveHintActions ?? new InteractiveHintActions(this._localStorageAgent);
        this._organizationActions = props.organizationActions ?? new OrganizationActions();
        this._textProvider = props.textProvider ?? new TextProvider();
        this._routesAgent = props.routesAgent ?? new RoutesAgent();
        this._userActions = props.userActions ?? new UserActions();
    }

    componentDidMount() {
        this.update();
    }

    update() {
        this.setState(state => { return { ...state, isLoaded : false } } );
        Promise.all([
            this._organizationActions.GetUsersOrganizationByToken(),
            this._employeeActions.getEmployeesInfoAsync(),
        ]).then(([managers, employees]) => this.setState(state => 
            { return { ...state, isLoaded: true, managers: managers, employees: employees } } ) )
    }

    inviteEmployee(e : EmployeeLocal) {
        this._employeeActions.sendInvitationAsync(e.employeeId).then(errorMessage => this.checkErrorMessage(errorMessage));
    }

    deleteEmployee(e : EmployeeLocal) {
        this._employeeActions.deleteAsync(e.employeeId).then(errorMessage => {
            if (errorMessage) {
                this.setState(state => { return { ...state, errorMessage: errorMessage }});
                return;
            }
            
            const manager = this.state.managers.find(m => m.email.toLowerCase() === e.email.toLowerCase());
            if (!manager) {
                this.update();
                return;
            }

            this.setState(state => { return { ...state, deletingId: manager.id }});
        });
    }

    inviteManager(m : BaseUser) {
        this._userActions.ResendConfirmationEmail(m.id).then(errorMessage => this.checkErrorMessage(errorMessage));
    }

    deleteManager(m : BaseUser) {
        this.deleteManagerInner(m.id);
    }

    deleteManagerInner(id : string) {
        this._userActions.DeleteUser(id).then(errorMessage => this.checkErrorMessage(errorMessage));
    }

    checkErrorMessage(message : string) {
        if (!message) {
            this.update();
            return;
        }

        this.setState(state => { return { ...state, errorMessage: message } } );
    }

    employeeManagerDeleteConfirm() {
        this.deleteManagerInner(this.state.deletingId);
        this.setState(state => { return { ...state, deletingId: '' }});
    }

    employeeManagerDeleteCancel() {
        this.setState(state => { return { ...state, deletingId: '' }});
        this.update();
    }

    render() {
        return <UsersUI 
                    textProvider={this._textProvider} 
                    interactiveHintActions={this._interactiveHintActions}
                    localStorageAgent={this._localStorageAgent}
                    routesAgent={this._routesAgent}
                    isLoaded={this.state.isLoaded}
                    employees={this.state.employees}
                    managers={this.state.managers}
                    errorMessage={this.state.errorMessage}
                    errorMessageClose={() => this.setState(state => { return { ...state, errorMessage: '' }})}
                    employeeManagerDeletePopupNeeded={!!this.state.deletingId}
                    employeeManagerDelete={() => this.employeeManagerDeleteConfirm()}
                    employeeManagerDeleteCancel={() => this.employeeManagerDeleteCancel()}
                    onUpdateRequired={() => this.update()}
                    inviteEmployee={e => this.inviteEmployee(e)}
                    deleteEmployee={e => this.deleteEmployee(e)}
                    inviteManager={m => this.inviteManager(m)}
                    deleteManager={m => this.deleteManager(m)}/>
    }
}
import React, { useEffect, useState } from "react"
import "./Header.css"
import BaseUser from "../../../Models/Users/BaseUser";
import PersonImg from "../../../Media/Img/person.png";
import BaseContainer from "../../../Containers/BaseContainer";
import AppContext from "../../../AppContext";
import RoutesUrl from "../../../Helpers/Routes/RoutesUrl";
import { Link } from "react-router-dom";

import HeaderMenuModel from "../../../Models/UI/HeaderMenuModel";
import GuidGenerator from "../../../Helpers/GuidGenetator";

interface IHeader{
    points : Array<HeaderMenuModel>
    activeNumber : number
    user : BaseUser
}

interface IState {
    isOpenSet : boolean
    
}


class HeaderClass extends BaseContainer<IHeader, IState> {

    constructor(props : any){
        super(props);
        this.state = {
            isOpenSet : false
        }
    }

    private readonly linkId = GuidGenerator.GenerateGuid();

    getTemplate(item : HeaderMenuModel, index : number) {
        return <Link to = {item.LinkTo} onClick = {item.Action} className = {(this.props.activeNumber == index) ? "activePointHeader" : "pointHeader"}>
            <div  className = { ((this.props.activeNumber == index) ? "linkSideMenuActive " : "linkSideMenu ") + this.linkId } >{item.Text}</div>
        </Link>
    }

    render(){
        let rows : JSX.Element[][] = [];
        this.props.points.forEach((item, index) => {
            let template = this.getTemplate(item, index);
            if (index % 2 === 0)
                rows.push([ template ]);
            else
                rows[rows.length - 1].push(template)
        })

        return <div className = "mockHeader">
            <header className="commonHeader">
                <div className="leftPartHeader">
                    {rows.map(row => <div>{row.map(item => item)}</div>)}
                </div>
                <div className="rightPartHeader" onClick={() => window.location.href=RoutesUrl.Settings}>
                    <div>{this.props.user.firstName + " " + this.props.user.lastName}</div>
                    <div className="pesronImg">
                        <img src={PersonImg} />
                    </div>
                </div>
            </header>
        </div>
    }

}

HeaderClass.contextType = AppContext;

export default HeaderClass;
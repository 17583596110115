import React from "react";

import Pair from '../Models/Pair'

import Ico from "../Media/Img/logo_actual_small.png";

export default class BaseContainer<IProps, IState> extends React.Component<IProps,IState>{
    constructor(prop:any){
        super(prop);
        this.initLogo();
    }

    initLogo = () => {
        let head = document.getElementsByTagName('head')[0];
        let link = document.createElement('link');
        link.setAttribute('href',Ico);
        link.setAttribute('type','image/x-icon');
        link.setAttribute('rel','shortcut icon');
        head.appendChild(link);
    }

    changeValueState(...items: Pair<string, any>[]) {
        this.setState(state => {
            let newState : any = state;
            items.forEach(item => newState[item.key] = item.value);
            return newState;
        });
    }
}

import React from 'react'

import AdminFrame from "../../../Components/Admin/AdminFrame/AdminFrame"
import ILanguagePhrase from '../../../Helpers/LanguagePhrase/ILanguagePhrase'
import { ITextProvider } from '../../../Helpers/TextProvider/TextProvider'
import BaseContainer from "../../BaseContainer"

import './BaseAdminContainer.css'

export interface IBaseAdminContainer {
    textProvider : ITextProvider
}

export interface IBaseAdminContainerState {
    widthVW : number
    headerHeight : number
}

export abstract class BaseAdminContainer<TProps extends IBaseAdminContainer, TState extends IBaseAdminContainerState> extends BaseContainer<TProps, TState> {
    public static readonly InitialState : IBaseAdminContainerState = { widthVW: 10, headerHeight: 60 }
    protected readonly _textProvider : ITextProvider;
    protected readonly _language : ILanguagePhrase;

    constructor(props: TProps) {
        super(props);
        this._language = (this._textProvider = props.textProvider).GetLang();
    }

    setWidth(v : number) {
        this.setState(state => {
            return {
                ...state,
                widthVW: v,
            };
        });
    }

    setHeight(v : number) {
        this.setState(state => {
            return {
                ...state,
                headerHeight: v,
            };
        });
    }

    abstract baseAdminContainerRender() : JSX.Element;

    render() {
        let props : React.CSSProperties = {
            marginLeft: `${(-(this.state.widthVW - AdminFrame.widthDefault))}vw`, 
            zIndex: 5,
            width: `${100 - AdminFrame.widthDefault}vw`,
            maxHeight: `${window.innerHeight - this.state.headerHeight}px`
        }

        return <AdminFrame 
                textProvider={this._language} 
                sideMenuWidthChanged={vw => this.setWidth(vw)} 
                adminHeaderHeightChanged={v => this.setHeight(v)}>
                <div className="BaseAdminContainerContainer" style={props}>
                    {this.baseAdminContainerRender()}
                </div>
        </AdminFrame>
    }
}
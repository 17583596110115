import React from 'react'

import ILanguagePhrase from '../../Helpers/LanguagePhrase/ILanguagePhrase'
import Language, { getValueByLang } from '../../Models/Users/Language'

import './InformationComponent.css'

interface IInformationComponent {
    textProvider : ILanguagePhrase
}

export default function InformationComponent(props : IInformationComponent) {


    return <div className = "InformationComponentContainer">
        <p className="Header">{props.textProvider.resourcesInformation}</p>
        <p className="SubHeader">{props.textProvider.programVersion + ": 7.8.0"}</p>

        <a target="_blank" className="PrimaryButton InformationComponentEmailLink" href="mailto:support@whyeva.com">{props.textProvider.connectDataScope}</a>
    </div>
}

/*
        <a className = "InformationComponentLink Prompt" target = "_blank" href = {getFaqRef(props.textProvider.lang)}>{props.textProvider.faq}</a>
        <a className = "InformationComponentLink Prompt" target = "_blank" href = "https://datascope.fr/CGV.html">{props.textProvider.termsOfSale}</a>
        <a className = "InformationComponentLink Prompt" target = "_blank" href = "https://datascope.fr/CGU.html">{props.textProvider.termsOfUse}</a>
        <a className = "InformationComponentLink Prompt" target = "_blank" href="https://datascope.fr/policy-platform.html">{props.textProvider.dataProtection}</a> */

function getFaqRef(lang : Language) : string {
    const en = "https://datascope.fr/introduction-guide-en.html";
    return getValueByLang<string>(lang, en, "https://datascope.fr/introduction-guide-fr.html", en);
}
import React from 'react'

import Footer from '../../../Components/Report/Footer/Footer'
import PopUpMessage from '../../../Components/Popups/PopUpMessage/PopUpMessage'
import PreloaderCompactDefault from '../../../Components/PreloaderCompact/PreloaderCompact'
import SimpleHeaderWithLinks from '../../../Components/SimpleHeaderWithLinks/SimpleHeaderWithLinks'

import CRMOrganization from '../../../Models/CRM/CRMOrganization'

import ILanguagePhrase from '../../../Helpers/LanguagePhrase/ILanguagePhrase'
import ILocalStorageAgent from '../../../LocalStorageWorker/ILocalStorageAgent'
import { ITokenParser } from '../../../Helpers/TokenParser'
import { IRoutesAgent } from '../../../Helpers/Routes/RoutesAgent'


import './CRMOrganizationsUI.css'

export const errorPopupButtonId = 'CRMOrganizationsUIErrorPopupButton',
             itemIdBase = 'CRMOrganizationsUIItem';

interface ICRMOrganizationsUI {
    tokenParser : ITokenParser
    localStorageAgent : ILocalStorageAgent
    routesAgent : IRoutesAgent
    language : ILanguagePhrase

    isLoaded : boolean
    errorMessage : string
    items : CRMOrganization[]
    onItemClicked(item : CRMOrganization) : void;
    onErrorClosed() : void
}

function getItemTemplate(item : CRMOrganization, index : number, onItemClicked : (item : CRMOrganization) => void) {
    const name = item.companyName ? item.companyName : '---'
    const email = item.adminCompanyEmail ? item.adminCompanyEmail : '';
    const phone = item.phone ? item.phone : '---'
    const date = item.dateRegistration.getFullYear() !== 0 && item.dateRegistration.getDate() !== 0 
                    ? item.dateRegistration.toISOString().substring(0, 10)
                    : '---';
    return <tr id={`${itemIdBase}_${index}`} key={index} onClick={() => onItemClicked(item)} style={{backgroundColor: index % 2 === 1 ? '' : '#ededed'}}>
        <td><p className='Prompt'>{name}</p></td>
        <td><p className='Prompt'>{email}</p></td>
        <td><p className='Prompt'>{phone}</p></td>
        <td><p className='Prompt'>{date}</p></td>
    </tr>
}

export default function CRMOrganizationsUI(props : ICRMOrganizationsUI) {
    let content;
    let errorPopup;
    if (!props.isLoaded) 
        content = <PreloaderCompactDefault />
    else {
        content = <table className='CRMOrganizationUITemplatesContainer' cellPadding='5'>
            <tr>
                <td><p className='SubHeader'>{props.language.companyName}</p></td>
                <td><p className='SubHeader'>{props.language.email}</p></td>
                <td><p className='SubHeader'>{props.language.companyPhone}</p></td>
                <td><p className='SubHeader'>{props.language.registrationDate}</p></td>
            </tr>
            {props.items.map((item, index) => getItemTemplate(item, index, props.onItemClicked))}
        </table>;

        if (!!props.errorMessage)
            errorPopup = <PopUpMessage 
                                zIndex={100} 
                                closePopup={() => props.onErrorClosed()} 
                                header={props.language.error} 
                                text={props.errorMessage} 
                                buttonId={errorPopupButtonId}/>
    }

    return <React.Fragment>
        <SimpleHeaderWithLinks 
            tokenParser={props.tokenParser} 
            localStorageAgent={props.localStorageAgent} 
            routesAgent={props.routesAgent} 
            language={props.language}/>
        {content}
        {errorPopup}
        <Footer text={props.language.footerText}/>
    </React.Fragment>
}
import React, { useState } from 'react'

import SimpleHeaderWithLinks from '../../../Components/SimpleHeaderWithLinks/SimpleHeaderWithLinks'
import PopUpMessage from '../../../Components/Popups/PopUpMessage/PopUpMessage'
import PreloaderCompactDefault from '../../../Components/PreloaderCompact/PreloaderCompact'
import Footer from '../../../Components/Report/Footer/Footer'

import Entity from '../../../Models/Organizations/Entity'
import CompanyInfo from '../../../Components/Admin/CompanyInfo/CompanyInfo'
import CRMOrganizationInfo from '../../../Models/CRM/CRMOrganizationInfo'
import UserInfo from '../../../Models/Users/UserInfo'
import UserInfoComponent from '../../../Components/UserInfoComponent/UserInfoComponent'

import ILanguagePhrase from '../../../Helpers/LanguagePhrase/ILanguagePhrase'
import ILocalStorageAgent from '../../../LocalStorageWorker/ILocalStorageAgent'
import { IRoutesAgent } from '../../../Helpers/Routes/RoutesAgent'
import { ITokenParser } from '../../../Helpers/TokenParser'

import './CRMOrganizationSettingsUI.css'
import { PopUpConfirmDefault } from '../../../Components/Popups/PopUpConfirm/PopUpConfirm'

const errorPopupButtonId = 'CRMOrganizationSettingsUIErrorPopupButton',
      deleteButtonId = 'CRMOrganizationUIDeleteButton',
      deletePopupConfirmButtonId = 'CRMOrganizationSettingsUIDeleteConfirm',
      deletePopupCancelButtonId = 'CRMOrganizationSettingsUIDeleteCancel';

export { errorPopupButtonId, deleteButtonId, deletePopupConfirmButtonId, deletePopupCancelButtonId }

interface ICRMOrganizationSettingsUI {
    tokenParser : ITokenParser
    localStorageAgent : ILocalStorageAgent
    routesAgent : IRoutesAgent
    language : ILanguagePhrase

    isLoaded : boolean
    errorMessage : string
    info : CRMOrganizationInfo
    onUserInfoUpdate(info : UserInfo) : Promise<boolean | undefined>
    onCompanyInfoUpdate(info : Entity) : Promise<boolean | undefined>
    onErrorClose() : void
    onDelete() : void
}

export default function CRMOrganizationSettingsUI(props : ICRMOrganizationSettingsUI) {
    const [ deleteConfirmationNeeded, deleteConfirmationNeededChange ] = useState(false);

    let content;
    let errorPopup;
    let deleteConfirmation;
    if (!props.isLoaded)
        content = <PreloaderCompactDefault />
    else {
        content = <div className="CRMOrganizationSettingsUIContentContainer">
            <UserInfoComponent 
                align='table' 
                info={UserInfo.fromUser(props.info.adminInfo)} 
                textProvider={props.language}
                actionText={props.language.update}
                action={info => props.onUserInfoUpdate(info)}
                onCancel={() => { }}/>
            <CompanyInfo 
                info={props.info.companyInfo}
                textProvider={props.language}
                onAction={info => props.onCompanyInfoUpdate(info)}/>
            <div className="CRMOrganizationSettingsUIRow">
                <div className="CRMOrganizationSettingsUIUsagesContainer Shadow">
                    <p className="Prompt">{`${props.info.managersCount} ${props.language.managers}`}</p>
                    <p className="Prompt">{`${props.info.employeeCount} ${props.language.employees}`}</p>
                    <p className="Prompt">{`${props.info.hierarchiesCount} ${props.language.structures}`}</p>
                </div>
                <button id={deleteButtonId} className="PrimaryButton" 
                        onClick={() => deleteConfirmationNeededChange(true)} 
                        style={{backgroundColor: '#dc3545'}}>{props.language.delete}</button>
            </div>
        </div>

        if (props.errorMessage)
            errorPopup = <PopUpMessage 
                            zIndex={100} 
                            closePopup={() => props.onErrorClose()} 
                            header={props.language.error} 
                            text={props.errorMessage}
                            buttonId={errorPopupButtonId}/>;

        if (deleteConfirmationNeeded)
            deleteConfirmation = <PopUpConfirmDefault
                                    zIndex={100} closePopup={() => deleteConfirmationNeededChange(false)}
                                    confirmButtonId={deletePopupConfirmButtonId} cancelButtonId={deletePopupCancelButtonId}
                                    text={props.language.crmOrganizationDeleteConfirmation} textProvider={props.language}
                                    onConfirm={() => props.onDelete()}/>
    }

    return <React.Fragment>
        <SimpleHeaderWithLinks 
            tokenParser={props.tokenParser} 
            localStorageAgent={props.localStorageAgent}
            routesAgent={props.routesAgent} 
            language={props.language} />
        {content}
        {errorPopup}
        {deleteConfirmation}
        <Footer text={props.language.footerText}/>
    </React.Fragment>
}
import ILanguagePhrase from "./ILanguagePhrase"
import LinkText from "../../Models/Link/LinkText";
import LinkPart from "../../Models/Link/LinkPart";
import RoutesUrl from "../Routes/RoutesUrl";
import Language from "../../Models/Users/Language";
import NumberOfEmployees, { getValueByNumberOfEmployees } from "../../Models/Organizations/NumberOfEmployees";
import Pair from "../../Models/Pair";
import EmployeeType from "../../Enums/EmployeeType";
import InteractiveHint from "../../Models/InteractiveHints/InteractiveHint";

export default class EnglishPhrase implements ILanguagePhrase{

    languageName = 'English';

    lang = Language.En;

    iso = 'en';

    bcp = 'en-US';

    emptyArea="The field must be filled";

    shortPassword='Password length must be over 8 characters'

    uncorrectPassword='Password must contain at least one uppercase letter and one number '

    uncorrectEmail='Invalid e-mail'

    uncorrectPhoneNumber="Invalid phone number"

    firstName="First Name"

    lastName="Last Name"

    email="E-mail"

    phoneNumber="Phone Number"

    login="Login"

    password="Password"

    passwordReset="Forgot the password?";

    passwordResetError="A user with such an email address was not found";

    passwordResetSuccess="A letter for changing the password has been sent to the specified email box";

    unsuccsesAuth="Incorrect email or password"

    duplicateEmail="This E-mail is already taken"

    signUpBut="Sign up"

    signInBut="Sign in"

    authorization="Authorization"

    registration="Registration"

    registrationDate="Registration date";

    tensity="Emotional well-being"

    anxiety = "Emotional adaptation"

    period = "By period"

    settings = "Settings"

    logOut = "Sign out"

    byMonth= "By month"

    byDay = "By day"

    byYear = "By year"

    byWeek = "By week"

    dateReport = "Report date"

    reportNotLoad = "The report has not been processed yet"

    dateActivity = "Date of activity"

    cancel = "Cancel"

    update = "Update";

    sucsessRegistrate = "You have successfully registered, check your email";

    yes = "Yes";

    no = "No";

    confirmEmail = "Check your email to verify your account";

    reports = "Reports";

    almostReadyOrNotInsufficientData = "The report is partially ready. The data is not availible for some departements";

    volume = "Communication activity";

    generalReport = "General report";

    good = "Good";

    risky = "Risky";

    bad = "Bad";

    reportDaily = "Daily report";

    createReport = "Create a new report";

    createReportPrompt = "Please, create your first report";

    reportBack = "Back to reports";

    reportLibrary = "Report library";

    reportType = "Report type"

    addDepartment = "Add department";

    detailedReportByDepartments = "Detailed report by departments";

    tensionDynamics = "Emotional well-being analysis";

    evolutionOfTensionDynamics = "Emotional adaptation analysis";

    volumeDynamics = "Employee communication activity analysis";

    personalInformation = "Рersonal information";

    companyInformation = "Company information";

    save = "Save";

    interfaceLanguage = "Interface language";

    resourcesInformation = "Resources & information";

    footerText = "(c) Intellectual property of WhyEva. All rights reserved";

    termsOfSale = "General Terms and Conditions of Sale";

    termsOfUse = "Terms and conditions for the application use";

    dataProtection = "Personal data protection rules";

    connectDataScope = "Connect to WhyEva";

    addNewReport = "Add new report";

    hereYouCanAdd = "Here you can add additional statistics in the future.";

    programVersion = "Program version";

    tensityHint = "the qualitative indicator defining the level of emotional positivity within the service"

    anxietyHint = "fluctuation of the emotional reaction of employees within the service"

    volumeHint = "the intensity of exchange within the service"

    reportHintTitle = "This illustration indicates the current psycho-emotional state in the company."

    reportHintSubTitle = "The scale consists of 5 values:";

    reportHintItems = [
        "5 - Extremely high degree",
        "4 - High degree",
        "3 - Medium degree",
        "2 - Low degree",
        "1 - Extremely low degree",
    ]

    interfaceSettings = "Interface settings";

    detailedReport = "Detailed report"

    managers = "Managers";

    managerAdd = "Add manager";

    managerAdded = "Manager added";

    managerBlock = "Block manager";

    managerUnblock = "Unblock manager";

    managerDeleteConfirmation = "Are you sure you want to delete this manager?";

    success = "Success!";

    uncorrectFirstName = "Enter your first name";

    uncorrectLastName = "Enter your last name"

    areYouSure = "Are you sure?";

    delete = "Delete";

    create = "Create"

    editProfile = "Edit profile";

    edit = "Edit";

    back = "Back";

    clear = "Clear";

    error = "Error";

    confirm = "Confirm";

    confirmation = "Confirmation";

    noPermissions = "Sorry, you are not authorized to access this page";

    adminInterface = "Administrator";

    accountSettings = "Account settings";

    attention = "Attention";

    balance = "Balance";

    balanceAmount = "Amount";

    balanceAmountPrompt = "Enter amount"

    balanceBillingHistory = "Billing history";

    balanceBillItem = "Item";

    balanceBillPrice = "Price";

    balanceBillTotal = "Total";

    balanceBillQuantity = "Quantity";

    balanceCurrentBalance = "Current balance";

    balanceCurrentTariff = "Current tariff";

    balanceCurrentTariffInclude = "Current tariff include:";

    balanceCurrentTrial = "Current free trial balance";

    balanceExpirationDate = "There will be enough money in the account until";

    balanceExpirationDateTrial = (daysCount : number) => `${daysCount} days before the end of the trial`;

    balanceInformation = "Subscription information";

    balanceInvoice = "Invoice";

    balanceInMonth = "/mo";

    balanceOutOfMoney = "This action is not provided by your tariff or you have run out of funds on your account";

    balancePay = "Pay";

    balancePayment = "Payment";

    balancePaymentAmount = "Payment amount";

    balancePaymentDate = "Date of payment";

    balancePaymentDetails = "Payment details";

    balancePaymentProcessing = "Your payment is processing";

    balancePayments = "Payments";

    balancePerEmployee = "per employee";

    balancePersonalRemain = "personal balance";

    balancePlanManage = "Manage plan";

    balancePricePerMonth = "Price per month";

    balancePrompt = "Keep track of your subscription status";

    balanceTariff = "Tariff";

    balanceRemaining = "Remaining balance";

    balanceRemainigTrial = "Remaining free trial balance";

    balanceSubscriptionsAndPayments = "Subscriptions & payments";

    balanceRecharge = "Recharge balance";

    balanceResult = "Remains";

    balanceUsageEmployees = (curr : number, total : number) => `${curr} of ${total} employees`;

    balanceUsageManagers = (curr : number, total : number) => `${curr} of ${total} manager`;

    balanceUsageStructures = (curr : number, total : number) => `${curr} of ${total} structure`;

    buisnessIdentifier = "Tax ID";

    connectSendInvite = "Send an invitation to connect";

    connectInvitation = "Invitation to connect";

    connectInvitationSure = "Are you sure you want to send an invitation to connect?";

    connectSendDate = "Date of sending the invitation";

    connectSubmittedDate = "Date the employee submitted access";

    connectRevokedDate = "Date when access was revoked";

    companyName = "Company name";

    companyAddress = "Legal address";

    companyPhone = "Office work phone";

    companyWebsite = "Company website";

    companyNumberOfEmployees = "Number of company employees";

    companyEmployeesNumber = (v : NumberOfEmployees) => getValueByNumberOfEmployees(v, 'Under 20', 'From 20 to 49', 'From 50 to 249', 'Over 250');

    currencyEuro = "Euro";

    crm ="CRM"

    crmOrganizationDeleteConfirmation = "Are you sure you want to delete this organization?";

    department = "Department";

    departmentDeleteConfirmation = (name : string, superName : string) => `Are you sure you want to delete the department ${name} that is a sub-department ${superName}?`;

    departmentName = "Department name";

    uncorrectDepartmentName = "Enter the name of the department";

    employees = "Employees";

    employeeAdd = "Add employee";

    employeeAddDepartment  = "Add to an additional division";

    employeeDeleteConfirm = "Are you sure you want to delete this employee?";

    employeeInviteQuestion = "The employee has been added. Send an invitation to connect?";

    employeeLinkDate = "Date of access submission";

    employeeMustHaveDepartment = "Link the employee to the department";

    faq = "Integration and usage of WhyEva";

    genderMale = "Male";

    genderFemale = "Female";

    interactiveAgain = "Show all hints again";

    interactiveDontShow = "Don't show hints";

    interactiveGotIt = "Clearly";

    interactiveHintAdminData = new InteractiveHint(this.adminInterface, "Configure the administrator and company data.\nThe company's data is used in the payment history to automatically fill out the payment form and prepare payment invoices for you.");

    interactiveHintEmployeesIntro = new InteractiveHint(this.employees, "Employees are users who have access to the Eva Chatbot.\nAn Employee may not be a Manager. No one except the Employee himself can get data to his profile settings, his responses or activity.\nEmployee can always disconnect from the system or delete his account.\nThe Employee can reset his password, if necessary, by getting a link to the email registered in his profile.");

    interactiveHintEmployeeEdit = new InteractiveHint(this.employees, "Send an invitation to an Employee to join the Chatbot.\nEnter the Employee's profile data.\nDelete the Employee, if necessary.\n/b/Attention/b/: The number of employees affects the tariffication!");

    interactiveHintEmployees = new InteractiveHint(this.employees, "Add Employees to the system by specifying their units or project teams.\n/b/Attention/b/: Be sure to choose the correct role for the employee in the hierarchy, because the role of the employee significantly affects the algorithm of the system!");

    interactiveHintHelp = new InteractiveHint("Technical Support", "We are always ready to answer your questions: support@whyeva.com.");

    interactiveHintIntro = new InteractiveHint("", "Welcome to the office of the corporate administrator of WhyEva.\nThese tips will allow you to get used to the system")

    interactiveHintManagersAdd = new InteractiveHint("Add Managers to the system", "Managers have access to Reports.\n/b/Attention/b/: The number of managers affects the tariff plan!");

    interactiveHintManagersEdit = new InteractiveHint(this.managers, "The user with the Manager role must receive an invitation to connect to the system.\nYou can change profile data, remove users from the system, or temporarily block users.");

    interactiveHintManagersIntro = new InteractiveHint(this.managers, "Managers are users who have access to company Reports.\nA manager can reset his password by getting a link to the email registered in his profile.\nYou will receive an email to confirm your registration.\nAfter confirming the email, you can log in to set up your profile.\n/b/Attention/b/: Use the original password from the email. You can change it after the first login.");

    interactiveHintManagersRight = new InteractiveHint(this.managers, "Users with the Manager role have access to:\n- the current reporting\n- the profile settings\n/b/Attention/b/: Managers do not have access to the Chatbot, if you need Manager's participation in the system, add Manager to the list of Employees.");

    interactiveHintFeatures = new InteractiveHint("The Administrator account allows you to:", "- Create accounts for Managers who will have access to Reports and analytical information (Managers section);\n- Create Employee accounts to access the Chatbot (section Employees);\n- Create organizations structures and project groups (section Structures);\n- Manage payments and set up payments (Balance section);\n- Manage profile data (section Settings);\n- Switch to the Manager mode and access to Reports (section Reports);\n- Log out of the system (section Sign out)");

    interactiveHintPaymentData = new InteractiveHint("Set up your payment information", "You pay only for the services you use.\nThe system keeps track of expenses on a daily basis and reminds you about billing if necessary.\n/b/Attention/b/: To test the system, you will be given a trial period of 15 days and funds in the amount of 200 euros. This will allow you to choose the appropriate parameters of the system and to experience all its possibilities.\nAfter your trial period is over, you will be able to choose your payment method.\nWe use the most secure payment methods and do not collect, process or save your payment information.");

    interactiveHintStructures = new InteractiveHint("Structures", "Create hierarchical organizations structures to be able to connect employees into divisions or to combine them into project teams.\nOrganization structures give you the ability to manage Reportings by division.\nManage your company's divisions by creating new departments.\n/b/Attention/b/: The number of used structures affects the tariffication!");

    interactiveHints = "Interactive hints";

    interactiveNext = "Next";

    noHierarchy = "Lack of hierarchy for generating reports";

    specialTariffMaxManagers = "The set limit for an individual plan has been reached, it is impossible to add a Manager! To change the tariff, contact support@whyeva.com";

    specialTariffMaxEmployees = "The set limit for an individual plan has been reached, it is impossible to add an Employee! To change the tariff, contact support@whyeva.com";

    specialTariffMaxStructures = "The set limit for an individual plan has been reached, it is impossible to add a Structure! To change the tariff, contact support support@whyeva.com";

    specialTariffPrompt = [ 'Special', 'Contact us' ];

    structure = "Structure";

    structureAdd = "Structure add";

    structureDelete = "Delete structure";

    structureDeleteConfirmation = "Are you sure of deleting this structure?";

    structureDepartmentMain = "Main department";

    structureDepartmentNumber = "Department code";

    structureDepartmentSubs = "Sub-departments";

    structureDepartmentSuperior = "Superior department"

    structureName = "Name of the structure";

    structureNameEnter = "Enter the name of the structure";

    structures = "Structures";

    structuresPrompt = `You can create your organization’s hierarchy here. To insert a new role, click ${this.structureAdd}`;

    paymentCountry = "Country";

    paymentEnterCountry = "Enter country";

    paymentAddress1 = "Address 1";

    paymentAddress2 = "Address 2";

    paymentEnterAddress = "Enter address";

    paymentCity = "City";

    paymentEnterCity = "Enter city";

    paymentZipCode = "ZIP code";

    paymentEnterZipCode = "Enter ZIP code";

    paymentTaxIdNumber = "Tax ID";

    paymentEnterBillingAddress = "Enter billing address";

    paymentEnterPaymentAmount = "Enter the amount of payment";

    paymentEnterTaxIdNumber = "Enter Tax ID Number";

    permissionBot = "Chat-bot access";

    permissionReporting = "Manager (reporting access)";

    permissions = "Access permissions";

    permissionSelectAny = "Select employee access rights";

    professionalExperience = "Professional experience";

    uncorrectProfessionalExperience = "Enter professional experience";

    uncorrectCompanyName = "Enter company name";

    uncorrectCompanyPhone = "Enter office work phone";

    uncorrectCompanyWebsite = "Enter company website";

    uncorrectCompanyAddress = "Enter legal company address";

    uncorrectNumberOfEmployees = "Enter number of company employees";

    months = "Months";

    years = "Years";

    dateBirth = "Birth date";

    uncorrectDateBirth = "Enter birth date";

    gender = "Gender";

    uncorrectGender = "Select gender";

    profession = "Profession";

    uncorrectProfession = "Specify the profession";

    position = "Role"

    positions = [
        new Pair(EmployeeType.Founder, 'Founder'),
        new Pair(EmployeeType.TopManagement, 'Top-management'),
        new Pair(EmployeeType.FunctionalManagement, 'Regional and funcitonal management'),
        new Pair(EmployeeType.MiddleManagement, 'Middle mangement'),
        new Pair(EmployeeType.LineManagement, 'Line management'),
        new Pair(EmployeeType.Contributor, 'Individual contributors'),
    ];

    uncorrectPosition = "Specify the role";

    hiredDate = "Hired date";

    uncorrectHiredDate = "Enter hired date";

    organization = "Organization";

    hierarchy = "Hierarchy";

    privacyPolicyAgree= [
        new LinkText("I accept "),
        new LinkPart("the User agreement ", RoutesUrl.privacyPolicyUrl),
        new LinkText("and "),
        new LinkPart("the data Protection policy", RoutesUrl.dataProtectionPolicyUrl),
        new LinkText(", and certify that I am of legal age")
    ]

    privacyDataAgree = [new LinkText("I expressly consent that my data may be used in the datasets of WhyEva")]
}

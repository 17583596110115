import React from 'react'

import BaseContainer from '../../BaseContainer'
import StructuresUI from './StructuresUI'

import IDepartmentActions from '../../../Actions/DepartmentActions/IDepartmentActions'
import DepartmentActions from '../../../Actions/DepartmentActions/DepartmentActions'
import IEmployeeActions from '../../../Actions/EmployeeActions/IEmployeeActions'
import EmployeeActions from '../../../Actions/EmployeeActions/EmployeeActions'
import OrganizationInfo from '../../../Models/OrganizationReport/OrganizationInfo'
import IHierarchyActions from '../../../Actions/HerarchyActions/IHierarchyActions'
import HierarchyActions from '../../../Actions/HerarchyActions/HierarchyActions'
import MainHierarchy from '../../../Models/Organizations/MainHierarchy'
import Department from '../../../Models/Organizations/Department/Department'
import DepartmentAdd from '../../../Models/Organizations/Department/DepartmentAdd'
import DepartmentView from '../../../Models/Organizations/Department/DepartmentView'
import Hierarchy from '../../../Models/Organizations/Hierarchy'
import HierarchyInfo from '../../../Models/OrganizationReport/HierarchyInfo'
import DepartmentGet from '../../../Models/Organizations/Department/DepartmentGet'
import Pair from '../../../Models/Pair'
import { ITextProvider, TextProvider } from '../../../Helpers/TextProvider/TextProvider'
import { IInteractiveHintActions, InteractiveHintActions } from '../../../Actions/InteractiveHintActions'
import LocalStorageAgent from '../../../LocalStorageWorker/LocalStorageAgent'
import { throws } from 'assert'

interface IStructures {
    textProvider : ITextProvider | null
    interactiveHintActions : IInteractiveHintActions | null
}

interface IStructuresState {
    isLoaded : boolean
    structures : Pair<Hierarchy, DepartmentView[]>[]

    errorMessage : string
}

export default class Structures extends BaseContainer<IStructures, IStructuresState> {
    private readonly _departmentActions : IDepartmentActions = new DepartmentActions();
    private readonly _employeeActions : IEmployeeActions = new EmployeeActions();
    private readonly _hierarchyActions : IHierarchyActions = new HierarchyActions();
    private readonly _textProvider : ITextProvider;
    private readonly _interactiveHintActions : IInteractiveHintActions;

    private readonly _organizationsPromise : Promise<OrganizationInfo[]>;

    constructor(props : IStructures) {
        super(props);
        this._textProvider = props.textProvider ?? new TextProvider();
        this._interactiveHintActions = props.interactiveHintActions ?? new InteractiveHintActions(new LocalStorageAgent());
        this.state = {
            isLoaded: false,
            structures: [],
            errorMessage: ''
        }

        this._organizationsPromise = this._employeeActions.getOrganizationsInfoByUserAsync();
    }

    componentDidMount(): void {

        this._organizationsPromise.then(orgs => {
            let hierarchies = orgs.reduce((acc, curr) => acc.concat(curr.hierarchies), [] as HierarchyInfo[]);
            let promises = hierarchies.map(o => this._hierarchyActions.GetHierarchyAsyncResponce(o.id));
            Promise.all(promises).then(results => {
                this.setState(state => {
                    return {
                        ...state,
                        isLoaded: true,
                        structures: results.map(curr => new Pair(curr, Hierarchy.ConvertToDepartmentView(curr))),
                    }
                })
            });
        })
    }

    extendStructure(dest : DepartmentView, src : DepartmentView) {
        if (dest.id !== src.id) 
            return;

        dest.isExtended = src.isExtended;
        dest.subDepartments.forEach(department => 
            src.subDepartments.forEach(srcDep => 
                this.extendStructure(department as DepartmentView, srcDep as DepartmentView)));
    }

    updateStructure(id : string) {
        this._hierarchyActions.GetHierarchyAsyncResponce(id).then(structure => {
            let structures = this.state.structures;
            for (let key in structures) {
                let struct = structures[key];
                if (struct.key.id === structure.id) {
                    let hierarchy = Hierarchy.ConvertToDepartmentView(structure);
                    this.extendStructure(hierarchy[0], structures[key].value[0]);
                    structures[key] = new Pair(structure, hierarchy);
                    break;
                }
            }

            this.setState(state => {
                return {
                    ...state,
                    structures: structures,
                };
            });
        });

    }

    addStructure(name : string, mainDepartmentName : string) {
        this._organizationsPromise.then(orgs => 
            this._hierarchyActions.AddHierarchyWithMainDepartmentAsync(new MainHierarchy(orgs[0].id, name), mainDepartmentName))
                                  .then(error => { 
                                      this.setState(state => { return { ...state, errorMessage : error }}); 
                                      if (!error) window.location.reload() 
                                    });
    }

    extendChanged(hierarchyId : string, struct : DepartmentView[]) {
        let structs = this.state.structures;
        for (let key in structs) {
            if (structs[key].key.id === hierarchyId) {
                structs[key].value = struct;
                break;
            }
        }

        this.setState(s => {
            return {
                ...s,
                structures: structs,
            };
        });
    }

    deleteDepartment(id : string) : void {
        this._departmentActions.DeleteDepartmentAsync(id).then(() => window.location.reload());
    }

    editDepartment(structure : Pair<Hierarchy, DepartmentView[]>, department : DepartmentView) : void {
        let model = this.getModel(department);
        this._departmentActions.UpdateDepartmentAsync(model).then(() => this.updateStructure(structure.key.id));
    }
    
    getModel(department : Department) : DepartmentGet {
        return new DepartmentGet(department.id, department.name, department.numberDepartment, department.superiorDepartment?.id ?? '');
    }

    addDepartment(superiorId : string, name : string, structureId : string) : void {
        let department = new DepartmentAdd('',superiorId,name,0);
        this._departmentActions.AddDepartmentAsync(department).then(() => this.updateStructure(structureId));
    }

    deleteStructure(id : string) : void {
        this._hierarchyActions.DeleteHierarchyAsync(id).then(() => window.location.reload());
    }

    editStructure(hierarchy : Hierarchy) {
        this._hierarchyActions.UpdateHierarchyAsync(hierarchy).then(_ => window.location.reload());
    }

    render() {
        return <StructuresUI
                    textProvider={this._textProvider}
                    interactiveHintActions={this._interactiveHintActions}
                    isLoaded={this.state.isLoaded} 
                    structures={this.state.structures}
                    addStructure={(name, mainDepartmentName) => this.addStructure(name, mainDepartmentName)}
                    extendChanged={(hierarchyId, struct) => this.extendChanged(hierarchyId, struct)}
                    deleteDepartment={id => this.deleteDepartment(id)}
                    editDepartment={(structure, department) => this.editDepartment(structure, department)}
                    addDepartment={(superiorId, name, structureId) => this.addDepartment(superiorId, name, structureId)}
                    deleteStructure={id => this.deleteStructure(id)}
                    editStructure={h => this.editStructure(h)}
                    errorMessage={this.state.errorMessage}
                    onCloseError={() => this.setState(state => { return { ...state, errorMessage : ''} })}/>
    }
}
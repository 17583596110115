import React from 'react'
import * as stripeJs from '@stripe/stripe-js'
import { Elements } from '@stripe/react-stripe-js';

import BaseContainer from '../../BaseContainer'
import PreloaderCompactDefault from '../../../Components/PreloaderCompact/PreloaderCompact'

import { IPaymentActions, PaymentActions } from '../../../Actions/PaymentActions';
import Pair from '../../../Models/Pair';
import PaymentInfo from '../../../Models/Payment/PaymentInfo';
import { IMyPopupBase, MyPopupBase } from '../../../Components/Popups/MyPopupBase/MyPopupBase';
import StripePaymentUI from './StripePaymentUI';
import ILanguagePhrase from '../../../Helpers/LanguagePhrase/ILanguagePhrase';

import "./StripePaymentUI.css";
import IUserAction from '../../../Actions/UserActions/IUserAction';
import UserActions from '../../../Actions/UserActions/UserActions';
import BaseUser from '../../../Models/Users/BaseUser';

interface IStripePayment extends IMyPopupBase {
    info : PaymentInfo
    textProvider : ILanguagePhrase
}

interface IStripePaymentState {
    isLoaded : boolean
    stripe : stripeJs.Stripe | null
    privateKey : string
    email : string
}

export default class Stripe extends BaseContainer<IStripePayment, IStripePaymentState> {
    private readonly _userActions : IUserAction = new UserActions();
    private readonly _paymentActions : IPaymentActions = new PaymentActions();

    private readonly _userInfoPromise : Promise<BaseUser>;
    private readonly _publicKeyPromise : Promise<string>;
    private readonly _privateKeyPromise : Promise<string>;

    constructor(props : IStripePayment) {
        super(props);
        this.state = {
            isLoaded : false,
            stripe : null,
            privateKey : '',
            email : ''
        };

        this._userInfoPromise = this._userActions.GetUserProfile();
        this._publicKeyPromise = this._paymentActions.getPublicKey();
        this._privateKeyPromise = this._paymentActions.getPrivateKey(props.info);
    }

    componentDidMount(): void {
        Promise.all([
            this._userInfoPromise.then(info => this.setState(state => { return { ...state, email: info.email }})),
            this._publicKeyPromise.then(publicKey => stripeJs.loadStripe(publicKey)).then(stripe => this.changeValueState(new Pair('stripe', stripe))),
            this._privateKeyPromise.then(privateKey => this.changeValueState(new Pair('privateKey', privateKey))),
        ]).then(() => this.changeValueState(new Pair('isLoaded', true)))
    }

    render() {
        let content;
        if (!this.state.isLoaded)
            content = <PreloaderCompactDefault />
        else
            content = <Elements 
                        options={{clientSecret: this.state.privateKey, appearance: {theme: 'stripe'}}} 
                        stripe={this.state.stripe}>
                <StripePaymentUI 
                    zIndex={this.props.zIndex*2}
                    privateKey={this.state.privateKey}
                    textProvider={this.props.textProvider}
                    email={this.state.email}/>
            </Elements>
            
        return <div className="StripePaymentUIPopupContainer"> 
            <MyPopupBase zIndex={this.props.zIndex} closePopup={() => this.props.closePopup()} width={'default'}>
                {content}
            </MyPopupBase>
        </div>
    }
}
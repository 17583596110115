import React, { useEffect } from "react";

import Header from "./Header/Header";

import ILanguagePhrase from "../../Helpers/LanguagePhrase/ILanguagePhrase";
import HeaderMenuModel from "../../Models/UI/HeaderMenuModel";
import RoutesUrl from "../../Helpers/Routes/RoutesUrl";
import BaseUser from "../../Models/Users/BaseUser";
import Language from "../../Models/Users/Language";
import { TokenParser } from "../../Helpers/TokenParser";
import Role from "../../Models/Role";

interface ISideMenuContainer{
    uri : string
    textProvider : ILanguagePhrase
}

function HeaderContainer(props : ISideMenuContainer){

    let user = new BaseUser("", "", "", "", "", "", "", "", Language.En, false);

    let userStr = localStorage.getItem("user");

    if(userStr){
        user = JSON.parse(userStr) as BaseUser;
    }
    else
        window.location.href = RoutesUrl.SignIn;

    let menu =  [
        HeaderMenuModel.GetSideMenuModel(props.textProvider.reports, RoutesUrl.Reports, () => {}),
        HeaderMenuModel.GetSideMenuModel(props.textProvider.settings, RoutesUrl.Settings, () => {}) ];

    let role = new TokenParser().GetRoles(localStorage.getItem('token') as string);
    if (role.some(r => r === Role.CompanyAdministrator))
        menu.push(HeaderMenuModel.GetSideMenuModel(props.textProvider.adminInterface, RoutesUrl.AdminInterfaceDefaultPath, () => {}));

    menu.push(HeaderMenuModel.GetSideMenuModel(props.textProvider.logOut, RoutesUrl.SignIn, () => {localStorage.removeItem("user"); localStorage.removeItem("token")}));

    let activePointNumber = menu.findIndex(item =>  item.isEqualsUriPattern(props.uri));

    let content = <Header user = {user} activeNumber = {activePointNumber} points = {menu}/> 

    return <React.Fragment>{content}</React.Fragment>;


}

export default HeaderContainer;
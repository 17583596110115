import React, { useState } from 'react'

import IconWithMenu from '../../../Components/IconWithMenu/IconWithMenu';
import InteractiveHintsManager from '../../../Components/InteractiveHints/InteractiveHintsManager';
import { PopUpConfirmDefault } from '../../../Components/Popups/PopUpConfirm/PopUpConfirm';

import BaseUser from '../../../Models/Users/BaseUser';
import ILanguagePhrase from '../../../Helpers/LanguagePhrase/ILanguagePhrase'
import { IInteractiveHintActions } from '../../../Actions/InteractiveHintActions';
import * as InteractiveHintsKeys from '../../../Helpers/InteractiveHintsKeys'
import { IRoutesAgent } from '../../../Helpers/Routes/RoutesAgent';
import Pair from '../../../Models/Pair'
import RoutesUrl from '../../../Helpers/Routes/RoutesUrl';

import EditWhite from "../../../Media/Img/editWhite.png"

import './ManagersTable.css'

export const tableId = 'ManagersTableTableId',
             inviteConfirmId = 'ManagersTableInviteConfirm',
             inviteCancelId = 'ManagersTableInviteCancel',
             deleteConfirmId = 'ManagersTableDeleteConfirm',
             deleteCancelId = 'ManagersTableDeleteCancel';

interface IManagersTable {
    interactiveHintActions : IInteractiveHintActions
    language : ILanguagePhrase
    routesAgent : IRoutesAgent
    currentUser : BaseUser
    managers : BaseUser[]

    invite(manager : BaseUser) : void
    delete(manager : BaseUser) : void
}

export default function ManagersTable(props : IManagersTable) {
    const [ requiredIndex, requiredIndexChange ] = useState(-1);
    const managerToAct = requiredIndex < 0 ? new BaseUser() : props.managers[requiredIndex];
    const [ sendInviteRequested, sendInviteRequestedChange ] = useState(false);
    const [ deleteRequested, deleteRequestedChange ] = useState(false);

    function managerToTableRow(user : BaseUser, index : number) : JSX.Element {
        const menuItems : Pair<string, () => void>[] = [
            new Pair(props.language.editProfile, () => props.routesAgent.navigateTo(`${RoutesUrl.ManagerSettings}?${RoutesUrl.idKey}=${user.id}`)),
        ];

        if (props.currentUser && user.id !== props.currentUser.id) {
            menuItems.splice(0,0,new Pair<string, () => void>(props.language.connectSendInvite, () => sendInviteRequestedChange(true)));
            menuItems.push(new Pair<string, () => void>(props.language.delete, () => deleteRequestedChange(true)));
        }

        return <tr>
            <td>{user.firstName}</td>
            <td>{user.lastName}</td>
            <td align="center">{user.email.toLowerCase()}</td>
            <td align="center">{correctDate(user.lastActivity)}</td>
            <td align="center" style={{paddingLeft:'0'}}>
                <IconWithMenu img={EditWhite} 
                              imgClicked={() => requiredIndexChange(requiredIndex === index ? -1 : index)} 
                              menuItems={menuItems} 
                              isMenuVisible={requiredIndex === index}/>
            </td>
        </tr>
    } 

    let sendInvitePopup;
    if (sendInviteRequested)
        sendInvitePopup = <PopUpConfirmDefault 
                            zIndex={100} closePopup={() => sendInviteRequestedChange(false)}
                            confirmButtonId={inviteConfirmId} cancelButtonId={inviteCancelId}
                            text={props.language.connectInvitationSure}
                            textProvider={props.language}
                            onConfirm={() => props.invite(managerToAct)}/>

    let deletePopup;
    if (deleteRequested)
        deletePopup = <PopUpConfirmDefault
                            zIndex={100} closePopup={() => deleteRequestedChange(false)}
                            confirmButtonId={deleteConfirmId} cancelButtonId={deleteCancelId}
                            text={props.language.managerDeleteConfirmation}
                            textProvider={props.language}
                            onConfirm={() => props.delete(managerToAct)}/>

    return <React.Fragment>
        <table id={tableId} className='ManagersTableTable'>
            <thead>
                <th style={{textAlign: 'start'}}>{props.language.firstName}</th>
                <th style={{textAlign: 'start'}}>{props.language.lastName}</th>
                <th>{props.language.email}</th>
                <th>{props.language.dateActivity}</th>
                <th></th>
            </thead>
            {props.managers.map((u, i) => managerToTableRow(u, i))}
            {sendInvitePopup}
            {deletePopup}
        </table>
        <InteractiveHintsManager 
            lang={props.language}
            interativehintActions={props.interactiveHintActions}
            infos={[
                {
                    hintKey : InteractiveHintsKeys.managersIntroHintKey,
                    hint : props.language.interactiveHintManagersIntro,
                    position : { anchorId : tableId, shiftH : 5, shiftV : 5 }
                },
                {
                    hintKey : InteractiveHintsKeys.managersAddHintKey,
                    hint : props.language.interactiveHintManagersAdd,
                    position : { anchorId : tableId, shiftH : 5, shiftV : 5 }
                },
                {
                    hintKey : InteractiveHintsKeys.managersEditHintKey,
                    hint : props.language.interactiveHintManagersEdit,
                    position : { anchorId : tableId, shiftH : 5, shiftV : 5 }
                },
                {
                    hintKey : InteractiveHintsKeys.managersRightHintKey,
                    hint : props.language.interactiveHintManagersRight,
                    position : { anchorId : tableId, shiftH : 5, shiftV : 5 }
                }
            ]}/>
    </React.Fragment>
}

function correctDate(d : string) {
    if (d.length < 10)
        return '-';

    return `${d.substring(8,10)}/${d.substring(5,7)}/${d.substring(0,4)}`;
}
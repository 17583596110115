import React from 'react'

import { IBaseAdminContainer, IBaseAdminContainerState, BaseAdminContainer } from '../Base/BaseAdminContainer'
import Switcher from '../../../Components/Switcher/Switcher';

import BaseUser from '../../../Models/Users/BaseUser';
import EmployeeLocal from '../../../Models/Employees/EmployeeLocal';

import './UsersUI.css'
import PreloaderCompactDefault from '../../../Components/PreloaderCompact/PreloaderCompact';
import EmployeesTable from './EmployeesTable';
import ManagersTable from './ManagersTable';
import { IRoutesAgent } from '../../../Helpers/Routes/RoutesAgent';
import ILocalStorageAgent from '../../../LocalStorageWorker/ILocalStorageAgent';
import EmployeeAddPopup from '../EmployeeAddPopup/EmployeeAddPopup';
import PopUpMessage from '../../../Components/Popups/PopUpMessage/PopUpMessage';
import { IInteractiveHintActions } from '../../../Actions/InteractiveHintActions';
import { PopUpConfirmDefault } from '../../../Components/Popups/PopUpConfirm/PopUpConfirm';

interface IUsersUI extends IBaseAdminContainer {
    interactiveHintActions : IInteractiveHintActions
    localStorageAgent : ILocalStorageAgent
    routesAgent : IRoutesAgent

    isLoaded : boolean
    employees : EmployeeLocal[]
    managers : BaseUser[]
    errorMessage : string
    errorMessageClose() : void

    employeeManagerDeletePopupNeeded : boolean
    employeeManagerDelete() : void
    employeeManagerDeleteCancel() : void

    onUpdateRequired() : void

    inviteEmployee(e : EmployeeLocal) : void
    deleteEmployee(e : EmployeeLocal) : void
    inviteManager(m : BaseUser) : void
    deleteManager(m : BaseUser) : void
}

interface IUsersUIState extends IBaseAdminContainerState {
    mode : 'managers' | 'employees'
    addPopupNeeded : boolean
}

export default class UsersUI extends BaseAdminContainer<IUsersUI, IUsersUIState> {
    constructor(props : IUsersUI) {
        super(props);
        this.state = {
            ...BaseAdminContainer.InitialState,
            mode: 'employees',
            addPopupNeeded: false,
        }
    }

    onModeSelected(mode : 'managers' | 'employees') {
        this.setState(state => { return { ...state, mode : mode } } );
        this.props.onUpdateRequired();
    }

    getContent() : JSX.Element {
        if (!this.props.isLoaded) return <PreloaderCompactDefault />

        let table : JSX.Element;
        if (!this.props.isLoaded)
            table = <PreloaderCompactDefault />
        else {
            if (this.state.mode === 'employees')
                table = <EmployeesTable 
                            interactiveHintActions={this.props.interactiveHintActions}
                            language={this._language} 
                            routesAgent={this.props.routesAgent} 
                            employees={this.props.employees}
                            inviteEmployee={e => this.props.inviteEmployee(e)}
                            deleteEmployee={e => this.props.deleteEmployee(e)}/>
            else
                table = <ManagersTable
                            interactiveHintActions={this.props.interactiveHintActions}
                            language={this._language}
                            routesAgent={this.props.routesAgent}
                            currentUser={this.props.localStorageAgent.user as BaseUser}
                            managers={this.props.managers}
                            invite={m => this.props.inviteManager(m)}
                            delete={m => this.props.deleteManager(m)}/>
        }
        
        return <div className="UsersUITableContainer">
            {table}
            <p className="Prompt UsersUIAdd" 
               onClick={() => this.setState(state => { return { ...state, addPopupNeeded: true}})}
               >+ {this.state.mode === 'employees' ? this._language.employeeAdd : this._language.managerAdd}</p>
        </div>
    }

    baseAdminContainerRender(): JSX.Element {
        const content = this.getContent();

        let addPopup;
        if (this.state.addPopupNeeded)
            addPopup = <EmployeeAddPopup 
                            zIndex={100}
                            closePopup={() => this.setState(state => { return { ...state, addPopupNeeded: false }})}
                            textProvider={this._language}
                            routesAgent={this.props.routesAgent}/>

        let errorPopup;
        if (!!this.props.errorMessage)
            errorPopup = <PopUpMessage 
                            zIndex={200}
                            closePopup={() => this.props.errorMessageClose()}
                            header={this._language.error}
                            text={this.props.errorMessage}
                            buttonId='UsersUIErrorMessageOk'/>

        let deleteManagerPopup;
        if (this.props.employeeManagerDeletePopupNeeded)
            deleteManagerPopup = <PopUpConfirmDefault 
                                    zIndex={100}
                                    closePopup={() => this.props.employeeManagerDeleteCancel()}
                                    textProvider={this._language}
                                    confirmButtonId='UsersUIEmployeeManagerDeleteConfirm'
                                    cancelButtonId='UsersUIEmployeeManagerDeleteCancel'
                                    text={this._language.managerDeleteConfirmation}
                                    onConfirm={() => this.props.employeeManagerDelete()}/>

        return <div className="UsersUIContainer MainContainer">
            <div className='UsersUIHeader'>
                <p className='Header'>{this.state.mode === 'managers' ? this._language.managers : this._language.employees}</p>
                <Switcher
                    textFirst={this._language.employees} 
                    textSecond={this._language.managers} 
                    onFirstSelected={() => this.onModeSelected('employees')} 
                    onSecondSelected={() => this.onModeSelected('managers')}/>
            </div>
            {content}
            {addPopup}
            {errorPopup}
            {deleteManagerPopup}
        </div>
    }
}
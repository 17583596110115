import React from 'react';

import { IBaseAdminContainer, IBaseAdminContainerState, BaseAdminContainer } from "../Base/BaseAdminContainer";

import PersonImg from "../../../Media/Img/person.png";

import './BaseAdminSettings.css'

export interface IBaseAdminSettings extends IBaseAdminContainer {
    
}

export interface IBaseAdminSettingsState extends IBaseAdminContainerState {

}

export abstract class BaseAdminSettings<TProps extends IBaseAdminSettings, TState extends IBaseAdminSettingsState> extends BaseAdminContainer<TProps, TState> {
    public static readonly InitialState : IBaseAdminSettingsState = { ...BaseAdminContainer.InitialState }

    constructor(props : TProps) {
        super(props);
    }

    abstract getLink() : string;

    abstract onLinkClicked() : void;

    abstract getName() : string;

    abstract getButtons() : JSX.Element[];

    abstract getMainContent() : JSX.Element;

    baseAdminContainerRender() : JSX.Element {
        return <div className="BaseAdminSettings">
            <p className="BaseAdminSettingsLinkP">
                <span onClick={() => this.onLinkClicked()}>{this.getLink()}</span> 
                {'>'} 
                <span>{this.getName()}</span></p>
            <div className="BaseAdminSettingsInner">
                <div className="BaseAdminSettingsLinksContainer">
                    <img className="BaseAdminSettingsAvatar" src={PersonImg}/>
                    {this.getButtons()}
                </div>
                <div className="BaseAdminSettingsInfoContainer">
                    <p className="Header">{this._language.personalInformation}</p>
                    {this.getMainContent()}
                </div>
            </div>
        </div>
    }
}
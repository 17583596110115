interface IAction {
    () : void
}

class HeaderMenuModel {


    public static GetSideMenuModel(text : string,linkTo : string ,action : IAction = () => {}){
        return new HeaderMenuModel(text ,linkTo,action);
    }

    constructor(text : string, linkTo : string,action : IAction = () => {}){
        this.text = text;
        this.linkTo = linkTo;
        this.action = action;
    }

    private text : string;

    private linkTo : string;

    private action : IAction;

    public get Text(){
        return this.text;
    }

    public isEqualsUriPattern(uri : string){
        return uri.includes(this.linkTo);
    }

    public get LinkTo(){
        return this.linkTo;
    }

    public get Action(){
        return this.action;
    }

}

export default HeaderMenuModel;